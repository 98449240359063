import { KeycloakConfig } from 'keycloak-angular';

// Add here your keycloak setup infos
let keycloakConfig: KeycloakConfig = {
  url: 'https://auth.test.chief-pd.co.uk/auth',
  realm: 'chiefpd',
  clientId: 'web-app'
};

export const environment = {
  name: 'test',
  production: true,
  bannerText: 'THIS SYSTEM IS FOR TESTING AND SHOULD <strong>NOT</strong> BE USED WITH REAL DATA!',
  keycloak: keycloakConfig,
  api: 'https://api.test.chief-pd.co.uk/api',
  sentry_url: 'https://04ba15b847f34ff19ca3dbd2d78b7e2a@sentry.io/1511672',
  build: '671d986d'
};
